<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-5">
                <div class="row justify-content-center">
                  <div class="col-xl-8">
                    <div id="product-carousel" class="carousel slide product-detail-carousel" data-ride="carousel">
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <div>
                            <img :src="`${car.mainImage ? car.mainImage.url : ''}`" alt="product-img" style="width: 300px; height: 300px; object-fit: contain;" id="myImage" class="img-fluid" />
                          </div>
                        </div>

                        <div class="carousel-item" v-for="image in car.supportingImages">
                          <div>
                            <img :src="`${image ? image.url : ''}`" alt="product-img" style="width: 250px; height: 200px; object-fit: contain;" class="img-fluid" />
                          </div>
                        </div>

                      </div>
                      <ol class="carousel-indicators product-carousel-indicators mt-2">
                        <li data-target="#product-carousel" data-slide-to="0" class="active">
                          <img :src="`${car.mainImage ? car.mainImage.url: ''}`" @click="changeImage($event)" alt="product-img" class="img-fluid product-nav-img" />
                        </li>
                        <li data-target="#product-carousel" :data-slide-to="index+1" v-for="(image, index) in car.supportingImages">
                          <img :src="`${image.url}`" @click="changeImage($event)" alt="product-img" class="img-fluid product-nav-img" />
                        </li>

                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-7">
                <div>


                  <h4 class="mt-3 mb-2">Car Details</h4>
                  <div class="table-responsive">
                    <table class="table table-bordered table-centered mb-0">
                      <tbody>

                      <tr>
                        <td>Car name</td>
                        <td>
                          {{car.name}}
                          <a href="javascript: void(0);" class="text-muted" @click="$router.push('/rentals/edit-car/' + car.id)">
                            <i class="mdi mdi-square-edit-outline ml-2"></i>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Visibility status:
                        </td>
                        <td>
                          {{car.visibility}}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Notes :
                        </td>
                        <td>
                          <template v-if="car['notes'] === 'null' || !car['notes']">
                            No notes added
                          </template>
                          <template v-else>
                            {{car['notes']}}
                          </template>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Rental Status :
                        </td>
                        <td>
                          <div>
                            <span v-if="car.status === 'available' " class="badge badge-success p-1">{{ car.status }} </span>
                            <span v-if="car.status === 'in-progress' " class="badge badge-warning p-1 text-black-50" >{{ 'Processing' }} </span>
                            <span v-if="car.status === 'in-use' " class="badge badge-danger p-1" >{{ car.status }} </span>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <a href="#" class="btn btn-success mr-2" @click.prevent="$router.push('/rentals/edit-car/' + car.id)"><i class="fa fa-edit"></i> Edit Details</a>
                          <a href="#" class="btn btn-primary mr-2" @click.prevent="$router.push('/rentals/scheduled-rentals/' + car.id)"><i class="fa fa-list"></i> Scheduled Rental List</a>
                          <a href="#" class="btn btn-warning mr-2" v-if="rental && rental['status'] === 'assigned'" @click.prevent="cancelCarRentalAssignment(rental['id'])"><i class="fa fa-car"></i> Cancel car rental assignment</a>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <a href="#" class="btn btn-dark mr-2" @click.prevent="$router.push('/rentals/assign-car/' + car.id)"><i class="fa fa-car"></i> Assign car to a driver</a>
                        </td>
                      </tr>

                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->


            <div class="form-group mt-2">
              <label >Images of car's current state</label>
              <b-card>
                <template v-if="stateOfCarImages.length > 0">
                  <div class="row">
                    <template v-for="(file, index) in stateOfCarImages">
                      <div class="col-sm-3">
                        <div>
                          <b-img
                              thumbnail
                              :src="file.url"
                              width="200"
                              height="400"
                              style="object-fit: contain"
                              alt="image"
                          ></b-img>
                          <!--                    <p class="mb-0">-->
                          <!--                      <code>whatsapp-img-120202</code>-->
                          <!--                      <span class="badge p-1"-->
                          <!--                            :class="{'badge-warning': (file.current_status !== 'approved' && file.current_status !== 'accepted'), 'badge-success': (file.current_status === 'approved' || file.current_status === 'accepted')}"-->
                          <!--                      >{{file.current_status}}</span>-->
                          <!--                    </p>-->
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-else>
                  No images uploaded yet
                </template>
              </b-card>


<!--              <vue-dropzone-->
<!--                  id="stateOfCarImages"-->
<!--                  ref="stateOfCarImages"-->
<!--                  :use-custom-slot="true"-->
<!--                  :options="dropzoneOptionsStateOfCar"-->
<!--              ><div class="dz-message needsclick">-->
<!--                <i class="h1 text-muted ri-upload-cloud-2-line"></i>-->
<!--                <h3>No images uploaded yet</h3>-->
<!--              </div>-->
<!--              </vue-dropzone>-->
            </div>

            <h5 class="mt-3 mb-2">Specifications</h5>
            <div class="table-responsive">
              <table class="table table-bordered table-centered mb-0">

                <tbody>

                <tr v-for="spec in car.specifications">
                  <td>{{spec.title}}</td>
                  <td>{{spec.value}}</td>
                </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
    <template v-if="car['rentalDetail']">

      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Rental Information</h4>

            <div class="table-responsive">
              <table class="table table-bordered table-centered mb-0">
                <tbody>

                <template>
                  <tr>
                    <td>Rented by: </td>
                    <td> {{car['rentalDetail']['driver']['name']}} {{car['rentalDetail']['driver']['other_names']}}</td>
  <!--                  <td>{{car.rentalDetail.driver.name}} | {{car.rentalDetail.driver.phone }} | {{car.rentalDetail.driver.phone }}</td>-->
  <!--                  <td>{{car.rentalDetail.driver.name}} | {{car.rentalDetail.driver.phone }} | {{car.rentalDetail.driver.phone }}</td>-->
                  </tr>
                </template>


                <tr>
                  <td>Pickup date </td>
                  <td>{{car['rentalDetail']['startDate'] || car['rentalDetail']['start_date'] }}</td>
                </tr>
                <tr>
                  <td>Expected date to return car </td>
                  <td>{{car['rentalDetail']['endDate'] || car['rentalDetail']['end_date']}}</td>
                </tr>
                <tr>
                  <td>Expected rental duration</td>
                  <td>
                    {{JSON.parse(car['rentalDetail']['duration'])['display']}}
                  </td>
                </tr>
                <tr>
                  <td>Total cost: </td>
                  <td>{{car['rentalDetail']['totalCost'] || car['rentalDetail']['calculated_cost']}}</td>
                </tr>
                <tr>
                  <td>Initial base cost paid : </td>
                  <td>{{car['rentalDetail']['initial_fee_amount'] | toCurrencyFormat}}</td>
                </tr>
                <tr>
                  <td>Amount remaining to pay: </td>
                  <td><span class="mr-2">{{car['rentalDetail']['costRemaining'] || car['rentalDetail']['cost_remaining']}} </span>
                  </td>
                </tr>
                <tr>
                  <td>Departure Control Status: </td>
                  <td>{{ displayDepartureControlStatus(car.rentalDetail.departure_controls_status) }}</td>
                </tr>
                <tr>
                  <td>Arrival Control Status: </td>
                  <td>{{ displayArrivalControlStatus(car.rentalDetail.arrival_controls_status) }}</td>
                </tr>

                <tr>
                  <td>Assigned kilometers per day</td>
                  <td>
                    {{car['km_per_day']}} km
                  </td>
                </tr>

                <tr>
                  <td>Price of kilometers per day</td>
                  <td>
                    {{car['km_per_day_price']}} in €
                  </td>
                </tr>

                <tr>
                  <td>Kilometers before departure</td>
                  <td>
                    <template v-if="payload">
                      {{payload['km_before_departure']}} km
                    </template>
                    <template v-else>
                      N/A
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>Kilometers on arrival</td>
                  <td>
                    <template v-if="payload">
                      {{payload['km_on_arrival']}} km
                    </template>
                    <template v-else>
                      N/A
                    </template>
                  </td>
                </tr>
<!--                <tr>-->
<!--                  <td>Extra km used</td>-->
<!--                  <td>-->
<!--                    <template v-if="payload && payload['extra_km_used'] !== undefined">-->
<!--                      {{payload['extra_km_used']}} km-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      N/A-->
<!--                    </template>-->
<!--                  </td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td>-->
<!--                    Extra cost of km used-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <template v-if="payload && payload['extra_cost_of_km_used'] !== undefined">-->
<!--                      € {{payload['extra_cost_of_km_used']}}-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      N/A-->
<!--                    </template>-->
<!--                  </td>-->
<!--                </tr>-->

                </tbody>
              </table>
            </div>
            <h5 class="mt-3 mb-4 text-danger">Rental Controls</h5>
            <b-tabs>
              <b-tab title="Departure Controls" active>
                <div class="p-1"></div>
                <div class="row">
                  <div class="col-sm-6" v-for="control in car.rentalDetail.departureControls">

                    <div class="form-group">

                      <div class="mb-1 pt-1">
                        <label :for="'departure-'+control.id">{{control.label}}</label>
                        <input type="text" :id="'departure-'+control.id" class="form-control" :value="control.response">
                      </div>
                      <span v-if="control.required_for_arrival === 'yes'" class="text-danger "> Required</span>
                      <span v-else class="text-black-50"> Optional</span>
                    </div>

                  </div>
                </div>
                <h5 class="mt-3 mb-2 text-danger">Departure Control Images</h5>


                <b-card>
                  <template v-if="departureControlImages.length > 0">
                    <div class="row">
                      <template v-for="(file, index) in departureControlImages">
                        <div class="col-sm-3">
                          <div>
                            <b-img
                                thumbnail
                                :src="file.url"
                                width="200"
                                height="400"
                                style="object-fit: contain"
                                alt="image"
                            ></b-img>
                            <!--                    <p class="mb-0">-->
                            <!--                      <code>whatsapp-img-120202</code>-->
                            <!--                      <span class="badge p-1"-->
                            <!--                            :class="{'badge-warning': (file.current_status !== 'approved' && file.current_status !== 'accepted'), 'badge-success': (file.current_status === 'approved' || file.current_status === 'accepted')}"-->
                            <!--                      >{{file.current_status}}</span>-->
                            <!--                    </p>-->
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    No images uploaded yet
                  </template>
                </b-card>

                <hr>
                <router-link :to="'/rentals/perform-departure-control/'+car['rentalDetail']['id']" class="text-success">Click here to perform departure control for driver</router-link>
                <hr>

              </b-tab>
              <b-tab title="Arrival Controls">
                <div class="p-1"></div>
                <div class="row">
                  <div class="col-sm-6" v-for="control in car.rentalDetail.arrivalControls">

                    <div class="form-group">
                      <div class="mb-1 pt-1">
                        <label :for="'arrival-'+control.id">{{control.label}}</label>
                        <input :id="'arrival-'+control.id" type="text" class="form-control" :value="control.response">
                      </div>
                      <span v-if="control.required_for_arrival === 'yes'" class="text-danger "> Required</span>
                      <span v-else class="text-black-50"> Optional</span>

                    </div>

                  </div>
                </div>

                <h5 class="mt-3 mb-2 text-danger">Arrival Control Images</h5>
                <div class="row">
                  <div class="col-sm-12">

                    <b-card>
                      <template v-if="arrivalControlImages.length > 0">
                        <div class="row">
                          <template v-for="(file, index) in arrivalControlImages">
                            <div class="col-sm-3">
                              <div>
                                <b-img
                                    thumbnail
                                    :src="file.url"
                                    width="200"
                                    height="400"
                                    style="object-fit: contain"
                                    alt="image"
                                ></b-img>
                                <!--                    <p class="mb-0">-->
                                <!--                      <code>whatsapp-img-120202</code>-->
                                <!--                      <span class="badge p-1"-->
                                <!--                            :class="{'badge-warning': (file.current_status !== 'approved' && file.current_status !== 'accepted'), 'badge-success': (file.current_status === 'approved' || file.current_status === 'accepted')}"-->
                                <!--                      >{{file.current_status}}</span>-->
                                <!--                    </p>-->
                              </div>
                            </div>
                          </template>
                        </div>
                      </template>
                      <template v-else>
                        No images uploaded yet
                      </template>
                    </b-card>


                  </div>
                </div>
                <hr>
                  <router-link :to="'/rentals/perform-arrival-control/'+car['rentalDetail']['id']" class="text-info">Click her to perform arrival control for driver</router-link>
                <hr>
              </b-tab>
            </b-tabs>
        </div>
      </div>


      <form action="#" @submit.prevent="handCompleteDepartureControl">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Administrative Departure Control</h4>
            <div class="row">

              <div class="col-md-6">
                <div class="form-group">
                  <label>Set km before departure <span class="text-danger">in km</span></label>
                  <input type="text" class="form-control" v-model="departureForm.kmBeforeDeparture" required placeholder="Enter value here" :disabled="car['rentalDetail']['departure_controls_status'] === 'completed'">
                </div>
              </div>

            </div>

            <div class="row" v-if="car['rentalDetail']['departure_controls_status'] !== 'completed'">
              <div class="col-md-12">
                <button type="submit" class="btn btn-success"><i class="fas fa-check-circle"></i> Complete Departure Control</button>
              </div>
            </div>

          </div>
        </div>
      </form>

      <div class="card" v-if="calculatedWithTaxFields.length > 0">

        <div class="card-body">
          <h4 class="card-title">Administrative Arrival Control</h4>

          <form action="#" class="mt-4" @submit.prevent="handCompleteArrivalControl">

            <div class="row">

              <template v-if="calculatedWithTaxFields.length > 0">

                <template v-for="(field, index) in calculatedWithTaxFields">

                  <template v-if="field['identifier'] === 'extra_rental_cost'">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label> Set actual return date and time <span class="text-danger">*</span></label>
                            <div class="input-group input-group-merge">
                              <input type="datetime-local" class="form-control"
                                     placeholder="Enter value here"
                                     required
                                     v-model="arrivalForm.returnDateTime"
                              >
                            </div>
                            <span>Expected date of return: ( {{car['rentalDetail']['endDate'] || car['rentalDetail']['end_date']}} )</span>
                          </div>
                        </div>

                        <div class="col-md-12">

                          <div class="form-group">
                            <label> {{ field['fieldName'] }} in € </label>
                            <div class="input-group input-group-merge">
                              <input type="text" class="form-control"
                                     placeholder="Enter value here"
                                     step="0.01"
                                     :value="payload && payload['extra_cost_of_renting'] !== undefined ? payload['extra_cost_of_renting']['extraCost'] : ''"
                                     disabled
                              >
                            </div>

                            <span class="text-info">Extra duration used by driver: </span><span class="text-danger">{{arrivalForm.extraDuration || 'N/A'}}</span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-else-if="field['identifier'] === 'cost_of_fuel'">

                    <div class="col-md-6">
                      <hr>

                      <div class="row">

                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Specify the <span class="font-weight-bold text-danger">{{car['unit_of_fuel']}}s</span> used <span class="text-danger">in {{car['unit_of_fuel']}}s</span><span class="text-danger">*</span></label>
                            <div class="input-group input-group-merge">
                              <input type="text" class="form-control"
                                     placeholder="Enter value here"
                                     required
                                     v-model="numberOfFuelUnitsUsed"
                              >
                            </div>

                          </div>
                        </div>

                        <div class="col-md-12">

                          <div class="form-group">
                            <label> {{field['fieldName']}} <span class="text-danger">in €</span> </label>
                            <div class="input-group input-group-merge">
                              <input type="number" class="form-control"
                                     placeholder="Enter value here"
                                     step="0.01"
                                     disabled
                                     :value="payload && payload['cost_of_fuel_used'] !== undefined ? payload['cost_of_fuel_used'] : costOfFuelUsed"
                              >
                            </div>
                            <span>configured cost of fuel per {{car['unit_of_fuel']}}:  {{car['price_per_unit_of_fuel'] | toCurrencyFormat}} per {{car['unit_of_fuel']}}</span>

                          </div>
                        </div>
                      </div>
                    </div>

                  </template>

                  <template v-else-if="field['identifier'] === 'risk_of_damage'">

                    <div class="col-md-6">

                        <hr>
                        <div class="pt-1 pb-2">
                          <template v-if="hasReducedRiskOfDamage">
                            <span class="text-danger">This driver enabled recurring risk of damage</span>
                          </template>
                          <template v-else>
                            <span class="text-info text-bold">This driver has <span class="text-danger">NO</span> recurring risk of damage</span>
                          </template>
                        </div>
                        <div class="row">
                          <template v-if="hasReducedRiskOfDamage">
<!--                          <div class="col-md-6">-->

<!--                            <div class="form-group">-->
<!--                              <label>Gross risk of damage   <span class="text-danger">in €</span> <span class="text-danger">*</span></label>-->
<!--                              <div class="input-group input-group-merge">-->
<!--                                <input type="text" class="form-control"-->
<!--                                       placeholder="Enter value here"-->
<!--                                       disabled-->
<!--                                       :value="arrivalForm.grossRiskOfDamage"-->
<!--                                       step="0.01"-->
<!--                                >-->
<!--                              </div>-->

<!--                            </div>-->

<!--                          </div>-->
<!--                          <div class="col-md-6">-->

<!--                            <div class="form-group">-->
<!--                              <label>Initial risk of damage paid  <span class="text-danger">in €</span> <span class="text-danger">*</span></label>-->
<!--                              <div class="input-group input-group-merge">-->
<!--                                <input type="text" step="0.01" class="form-control"-->
<!--                                       placeholder="Enter value here"-->
<!--                                       disabled-->
<!--                                       :value="arrivalForm.initialRiskOfDamagePaid"-->
<!--                                >-->
<!--                              </div>-->

<!--                            </div>-->

<!--                          </div>-->
<!--                          <div class="col-md-6">-->

<!--                            <div class="form-group">-->
<!--                              <label>Configured recurring R.O.D  <span class="text-danger">in €</span> <span class="text-danger">*</span></label>-->
<!--                              <div class="input-group input-group-merge">-->
<!--                                <input type="text" step="0.01" class="form-control"-->
<!--                                       placeholder="Enter value here"-->
<!--                                       disabled-->
<!--                                       :value="arrivalForm.recurringRiskOfDamage"-->
<!--                                >-->
<!--                              </div>-->

<!--                            </div>-->

<!--                          </div>-->
                          <div class="col-md-12">

                            <div class="form-group">
                              <label> Remaining {{field['fieldName']}}  <span class="text-danger">in €</span></label>
                              <div class="input-group input-group-merge">
                                <input type="text" class="form-control"
                                       placeholder="Enter value here"
                                       :value="arrivalForm.arrivalRiskOfDamage"
                                       disabled
                                ><!--                                     v-model="field['fieldDefaultValue']"-->
                              </div>
                              <router-link to="/rentals/general-configurations" class="mt-2">Click her to check risk of damage setup</router-link>

                            </div>
                          </div>
                          </template>
                          <template v-else>
                            <div class="col-md-12">

                              <div class="form-group">
                                <label> Set total risk of damage </label>
                                <div class="input-group input-group-merge">
                                  <input type="text" class="form-control"
                                         placeholder="Enter value here"
                                         v-model="arrivalForm.arrivalRiskOfDamage"
                                  ><!--                                     v-model="field['fieldDefaultValue']"-->
                                </div>
                                <router-link to="/rentals/general-configurations" class="mt-2">Click her to check risk of damage setup</router-link>

                              </div>
                            </div>
                          </template>
                        </div>



                    </div>

                  </template>

                  <template  v-else-if="field['identifier'] === 'extra_km'">

                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Kilometers on arrival <span class="text-danger">in km</span><span class="text-danger">*</span></label>
                            <div class="input-group input-group-merge">
                              <input type="text" class="form-control"
                                     placeholder="Enter value here"
                                     required
                                     v-model="arrivalForm.kmOnArrival"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">

                          <div class="form-group">
                            <label> {{field['fieldName']}}  <span class="text-danger">in €</span> </label>
                            <div class="input-group input-group-merge">
                              <input type="text" class="form-control"
                                     placeholder="Enter value here"
                                     :value="payload && payload['extra_cost_of_km_used'] !== undefined ? payload['extra_cost_of_km_used'] : ''"
                                     disabled
                              >
                              <!--                                     v-model="field['fieldDefaultValue']"-->
                            </div>
<!--                            <span class="text-info">{{arrivalForm.totalDurationInDays}} days</span>-->
<!--                            <br>-->
                            <span class="text-dark"> This is calculated as follows: (extra kilometers used x price per kilometer)</span>
                            <!--                            <span class="text-info">assigned km per day: </span><span class="text-danger">{{car['km_per_day']}} km</span>-->
                            <!--                            <br>-->
                            <!--                            <span class="text-info">cost of km per day: </span><span class="text-danger">{{car['km_per_day']}} in €</span>-->

                          </div>
                        </div>
                        <!--                        <h4 class="card-title">Breakdown</h4>-->
                      </div>
                    </div>

                  </template>

                  <template v-else-if="field['identifier'] === 'base_rental_cost'">

                    <div class="col-md-6">

                      <hr>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label> {{field['fieldName']}}  <span class="text-danger">in €</span></label>
                            <div class="input-group input-group-merge">
                              <input type="text" class="form-control"
                                     placeholder="Enter value here"
                                     :value="arrivalForm.baseRentalCost"
                                     disabled
                              >
                            </div>

                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label> Remaining rental cost  <span class="text-danger">in €</span></label>
                            <div class="input-group input-group-merge">
                              <input type="text" class="form-control"
                                     placeholder="Enter value here"
                                     :value="arrivalForm.remainingRentalCost"
                                     disabled
                              >
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </template>

                  <template v-else>
                    <div class="col-md-6">

                      <hr>

                      <div class="form-group">
                        <label> {{field['fieldName']}}  <span class="text-danger">in €</span> </label>
                        <div class="input-group input-group-merge" >
                          <input type="number" class="form-control"
                                 step="0.01"
                                 placeholder="Enter value here"
                                 v-model="field['fieldDefaultValue']"
                          >
                          <!--                          field['fieldDefaultValue']-->
                        </div>

                      </div>
                    </div>
                  </template>

                </template>

              </template>


            </div>

            <hr>

            <div class="row">
              <div class="col-md-12">
                <button type="submit" class="btn btn-danger mr-2" @click="confirmArrivalControl = false">
                  <template v-if="!saved">
                    <i class="fas fa-eye"></i> Complete administrative arrival control
                  </template>
                  <template v-else><i class="fas fa-redo"></i> Save arrival control again</template>
                </button>
                <button type="submit" class="btn btn-success" v-if="saved" @click="confirmArrivalControl = true"><i class="fas fa-check-circle"></i> Confirm arrival control</button>
              </div>
            </div>



          </form>

        </div>
      </div>

    </template>


  </div>
</template>

<script>
import {
  products
} from "./data";
import vue2Dropzone from "vue2-dropzone";
import {confirm} from "../../../../utils/functions";

/**
 * Product-detail component
 */
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: '#',
        maxFilesize: 0.5,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        addRemoveLinks: false,
        autoProcessQueue: false,
        maxFiles: 20,
      },
      // dropzoneOptionsStateOfCar: {
      //   url: '#',
      //   maxFilesize: 0.5,
      //   acceptedFiles: ".jpeg,.jpg,.png,.gif",
      //   addRemoveLinks: false,
      //   autoProcessQueue: false,
      //   maxFiles: 20,
      // },
      numberOfFuelUnitsUsed: 0,
      hasReducedRiskOfDamage: false,
      saved: false,
      calculatedWithTaxFields: [],
      stateOfCarImages: [],
      departureControlImages: [],
      arrivalControlImages: [],
      costFields: [
        {
          fieldName: '',
          fieldValue: '',
          fieldType: 'cost', // income / cost
          weeklyPayment: true,
          fAdministration: true,
        }
      ],
      departureForm: {
        kmBeforeDeparture: ''
      },
      arrivalForm: {
        kmOnArrival: '',
        returnDateTime: '',
        extraDuration: '',
        grossRiskOfDamage: '',
        initialRiskOfDamagePaid: '',
        recurringRiskOfDamage: '',
        remainingRiskOfDamage: '',
        arrivalRiskOfDamage: '',
        remainingRentalCost: '',
        baseRentalCost: '',
        totalDurationInDays: ''

      },
      rental: null,
      confirmArrivalControl: false,
      payload: null,

    }
  },
  computed: {
      car(){
        return this.$store.getters.GET_SELECTED_CAR;
      },
      costOfFuelUsed(){
       return this.numberOfFuelUnitsUsed * (this.car['price_per_unit_of_fuel'] || 0.00)
      }

  },
  watch: {

    car: function (newValue, oldValue) {

      console.log('car: ', newValue)

      if(newValue){

        if(this.car['stateOfCar']){

          // this.$refs.stateOfCarImages.removeAllFiles();
          this.stateOfCarImages = [];

          this.car['stateOfCar'].forEach(e => {

            let file = { size: e.size, name: e.name, type: e.type };
            let url = e.url;
            this.stateOfCarImages.push({
              url: url,
              name: e.name
            })
            // this.$refs.stateOfCarImages.manuallyAddFile(file, url);

          })
        }

      }

      if(newValue && newValue['rentalDetail']){

        this.calculatedWithTaxFields = JSON.parse(newValue['rentalDetail']['calculated_with_tax_fields'])

        this.rental = newValue['rentalDetail']
        const payload = JSON.parse(newValue['rentalDetail']['payload']);
        if(payload){
          this.payload = payload;
          this.arrivalForm.kmOnArrival = payload['km_on_arrival'];
          this.arrivalForm.returnDateTime = payload['actual_return_date_time'];
          this.arrivalForm.extraDuration = payload['extra_cost_of_renting'] ? payload['extra_cost_of_renting']['extraDuration']['display'] : '';

          //  Fuel
          this.numberOfFuelUnitsUsed = payload['number_of_units'] || ''
          this.numberOfFuelUnitsUsed = payload['number_of_units'] || ''
        }

        const dControlImages = newValue.rentalDetail.departureControlImages;
        if(dControlImages){
          this.departureControlImages = [];
          dControlImages.forEach(e => {
            let file = { size: e.size, name: e.name, type: e.type };
            let url = e.url;
            // this.$refs.departureControlImages.manuallyAddFile(file, url);
            this.$nextTick(()=>{
              //this.$refs.departureControlImages.manuallyAddFile(file, url);
              this.departureControlImages.push({
                url: url,
                name: e.name
              })
            });

          })
        }


        const aControlImages = newValue.rentalDetail.arrivalControlImages;
        if(aControlImages){

          this.arrivalControlImages = [];
          aControlImages.forEach(e => {
            let file = { size: e.size, name: e.name, type: e.type };
            let url = e.url;
            // this.$refs.arrivalControlImages.manuallyAddFile(file, url);
            this.$nextTick(()=>{
              //this.$refs.arrivalControlImages.manuallyAddFile(file, url);
              this.arrivalControlImages.push({
                url: url,
                name: e.name
              })
            });
          })
        }

        if(newValue['rentalDetail']['payload'] && newValue['rentalDetail']['departure_controls_status'] === 'completed'){
          this.departureForm.kmBeforeDeparture = JSON.parse(newValue['rentalDetail']['payload'])['km_before_departure']
        }


        /// Risk of damage section -------------
        let riskOfDamage = newValue['rentalDetail']['risk_of_damage']
        if(riskOfDamage){
          riskOfDamage = JSON.parse(riskOfDamage);
          this.arrivalForm.initialRiskOfDamagePaid = riskOfDamage['reduced_risk_of_damage']
          this.arrivalForm.recurringRiskOfDamage = riskOfDamage['recurring_price_per_day']
          this.hasReducedRiskOfDamage = riskOfDamage['hasReducedRiskOfDamage']
          this.arrivalForm.grossRiskOfDamage = riskOfDamage['gross_risk_of_damage']
          this.arrivalForm.remainingRiskOfDamage = riskOfDamage['remaining_risk_of_damage'] || ''
        }
        console.log('riskOfDamage: ' , riskOfDamage)

      //  Rental cost over here ------------------------------------------
        this.arrivalForm.baseRentalCost = newValue['rentalDetail']['calculated_cost']
        this.arrivalForm.remainingRentalCost = newValue['rentalDetail']['base_rental_cost_on_arrival']
        this.arrivalForm.totalDurationInDays = newValue['rentalDetail']['totalDurationInDays']
        this.arrivalForm.arrivalRiskOfDamage = newValue['rentalDetail']['risk_of_damage_on_arrival']

        this.saved = newValue['rentalDetail']['arrival_control_status'] === 'saved';

      }
    }
  },
  middleware: "router-auth",
  methods: {
    /**
     * Change the product
     */
    changeImage(data) {
      document.getElementById("myImage").src = data.target.src
    },
    cancelCarRentalAssignment(rentalId){
      confirm('Cancel this rental', () => {
        this.$store.dispatch('cancelRentRequest', rentalId).then(() => {
          this.rental = null;
        })
      })
    },
    displayDepartureControlStatus(value){
      if(!value)
        return "Not completed";

      if(value === "completed")
        return "Driver has received car keys";

      return value;

    },

    displayArrivalControlStatus(value){
      if(!value)
        return "Not completed";

      if(value === "completed")
        return "Driver has returned car keys";

      return value;

    },
    handCompleteDepartureControl(){
        const payload = {
          'km_before_departure': this.departureForm.kmBeforeDeparture,
          'id': this.rental.id,
        }
        this.$store.dispatch('completeDepartureControl', payload)
    },
    handCompleteArrivalControl(){
      let mess = '';
      if(this.confirmArrivalControl){
        mess = 'confirm arrival control completion'
      }else{
        mess = 'save arrival control'
      }
      confirm(mess, () => {
        const payload = {
          'km_on_arrival': this.arrivalForm.kmOnArrival,
          'actual_return_date': this.arrivalForm.returnDateTime,
          'id': this.rental.id,
          'fuel_used': this.numberOfFuelUnitsUsed,
          'confirm': this.confirmArrivalControl,
          'total_risk_of_damage': this.arrivalForm.arrivalRiskOfDamage,
          'calculatedWithTaxFields': this.calculatedWithTaxFields
        }

        console.log('payload: ' ,payload)
        this.$store.dispatch('completeArrivalControl', payload).then(() => {
          this.$forceUpdate()
        })
      })
    },
  },
  created() {
    const carId = this.$route.params.id;
    this.$store.dispatch('getCarDetail', carId)
  }
};
</script>